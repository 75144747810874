import { RouteObject } from 'react-router-dom';

import { PrivateRoute } from '../lib/auth/auth';
import AnaplanJobDetails from '../pages/anaplan-joblog-page';
import AnaplanScheduler from '../pages/anaplan-scheduler-page';
import ArgusJobDetails from '../pages/argus-jobdetails-page';
import BlackLineBankTransaction from '../pages/blackline-banktransaction-page';
import BlackLineJobDetails from '../pages/blackline-jobdetails-page';
import BlackLineScheduler from '../pages/blackline-scheduler-page';
import Home from '../pages/home';

import { ROUTES } from './routes';

export const routeItems: RouteObject[] = [
  {
    element: <BlackLineJobDetails />,
    path: ROUTES.blackline.path,
    id: 'blackline',
  },
  {
    element: <BlackLineScheduler />,
    path: ROUTES.blackline.submenu.scheduler.path,
    id: 'blackline-scheduler',
  },
  {
    element: <BlackLineBankTransaction />,
    path: ROUTES.blackline.submenu.banktransaction.path,
    id: 'blackline-banktrancation',
  },
  {
    element: <AnaplanJobDetails />,
    path: ROUTES.anaplan.path,
    id: 'anaplan',
  },
  // {
  //   element: <AnaplanScheduler />,
  //   path: ROUTES.anaplan.submenu.scheduler.path,
  //   id: 'anaplan-scheduler',
  // },
  {
    element: <ArgusJobDetails />,
    path: ROUTES.argus.path,
    id: 'argus',
  },
];

export const routesConfig = (userRole: string[]) => {
  if (!userRole) {
    return;
  }

  const RoutesConfig: RouteObject[] = [
    {
      element: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
      path: '*',
      children: [],
      id: 'home',
    },
  ];

  if (userRole.some((x) => x.toLowerCase() === 'admin')) {
    return [...RoutesConfig, ...Object.values(routeItems)];
  } else {
    userRole.forEach((role) => {
      var items = routeItems.filter((x) => x.id.startsWith(role.toLowerCase()));
      RoutesConfig.push(...items);
    });
    return RoutesConfig;
  }
};
