import EditIcon from '@mui/icons-material/Edit';
import {
  AlertColor,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import AddProperties from '../dataservice/argus/add-properties';
import DeleteIncrementalHistory from '../dataservice/argus/delete-incremental-history';
import GLAccounts from '../dataservice/argus/gl-accounts';
import InfoSection from '../dataservice/argus/info-section';
import ArgusLogs from '../dataservice/argus/logs';
import ModuleSelection from '../dataservice/argus/module-selection';
import PropertySelection from '../dataservice/argus/property-selection';
import Scenario from '../dataservice/argus/scenario';
import UnitCleanUp from '../dataservice/argus/unitcleanup';
import UpdateManagementFee from '../dataservice/argus/update-managementfee';
import ConfirmDialog from '../hooks/ConfirmationPopUp';
import ToastAlert from '../hooks/toast-alert';
import { useAuthContext } from '../lib/auth/AuthContext';
import { fetchData, postData } from '../lib/fetchData';

const ArgusJobDetails = () => {
  const [openPropertySelection, setOpenPropertySelection] = useState(false);
  const [selectPropertyValue, setSelectPropertyValue] = useState<string>('');
  const [useTemplate, setUseTemplate] = useState(false);
  const [openTemplateConfirmation, setOpenTemplateConfirmation] =
    useState<boolean>(false);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(0);
  const [properties, setProperties] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [glModuleModel, setGlModuleModel] = useState();
  const [selectedModulesModel, setSelectedModulesModel] = useState({
    includePropertyHeader: false,
    includeContractualTenants: false,
    includeGLData: false,
    includeRecoveries: false,
  });
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [openTerminateConfirmation, setOpenTerminateConfirmation] =
    useState<boolean>(false);
  const { state } = useAuthContext();
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    type: AlertColor;
    message: string;
  }>({
    showAlert: false,
    type: null,
    message: null,
  });
  const [systemConfig, setSystemConfig] = useState<any>();
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [includePropertyClassification, setincludePropertyClassification] =
    useState(false);
  const [managementFeeAutoRun, setManagementFeeAutoRun] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<any[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [distinctTemplatePortfolios, setDistinctTemplatePortfolios] = useState<
    any[]
  >([]);
  const [selectedTemplatePortfolio, setSelectedTemplatePortfolio] =
    useState<any>(null);

  const [tempRowIds, setTempRowIds] = useState([]);
  const [tempProperties, setTempProperties] = useState([]);
  const [
    tempIncludePropertyClassification,
    setTempIncludePropertyClassification,
  ] = useState(false);
  const [addedProperties, setAddedProperties] = useState([]);
  const [openAddedProperties, setOpenAddedProperties] = useState(false);

  useEffect(() => {
    setSelectPropertyValue('all');
    setOpenBackDrop(true);
    fetchData(`systemconfiguration`, (data) => {
      setSystemConfig(data);
      setOpenBackDrop(false);
    });

    fetchData(`templates`, (data) => {
      setTemplates(data);

      const result = [];
      const map = new Map();
      for (const item of data) {
        if (!map.has(item.portfolioId)) {
          map.set(item.portfolioId, true); // set any value to Map
          result.push({
            id: item.portfolioId,
            name: item.portfolioName,
          });
        }
      }
      setDistinctTemplatePortfolios(result);
    });
  }, []);

  useEffect(() => {
    setOpenBackDrop(true);

    fetchData(
      `properties?includeClassification=${includePropertyClassification}`,
      (data) => {
        setOpenBackDrop(false);
        setProperties(data);

        if (includePropertyClassification) {
          setSelectedRowIds([]);
          return;
        }

        if (!includePropertyClassification) {
          setSelectedRowIds(Array.from({ length: data.length }, (_, i) => i));
        }
      },
    );
  }, [includePropertyClassification, setProperties, setSelectedRowIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedTemplate === null && templates.length > 0) {
      const defaultTemplate = templates.find(
        (x) => x.templatePropertyId === systemConfig?.templateID,
      );
      setSelectedTemplate(defaultTemplate);

      const defaultTemplatePortfolio = distinctTemplatePortfolios.find(
        (x) => x.id === defaultTemplate?.portfolioId,
      );
      setSelectedTemplatePortfolio(defaultTemplatePortfolio);
      if (!defaultTemplatePortfolio) {
        return;
      }
      setFilteredTemplates(
        templates.filter((x) => x.portfolioId === defaultTemplatePortfolio.id),
      );
    }
  }, [templates, systemConfig, selectedTemplate, distinctTemplatePortfolios]);

  const onExecute = (isCancel: boolean) => {
    let propModel = {};
    if (isCancel) {
      propModel = {
        isCancelJobRun: true,
        scheduledUser: state.user.name,
      };
    } else {
      propModel = {
        useTemplate: useTemplate,
        includePropertyHeader: selectedModulesModel.includePropertyHeader,
        includeContractualTenants:
          selectedModulesModel.includeContractualTenants,
        includeGLData: selectedModulesModel.includeGLData,
        includeRecoveries: selectedModulesModel.includeRecoveries,
        propertyModule: {
          includeAllProperties: selectPropertyValue === 'all',
          selectedProperties: getSelectedProperties(),
        },
        gLModule: glModuleModel,
        scheduledUser: state.user.name,
        isManagementFeeAutoRun: managementFeeAutoRun,
        templateId:
          selectedTemplate !== null && useTemplate
            ? selectedTemplate.templatePropertyId
            : systemConfig.templateID,

        integrationScenarioId:
          selectedIntegrationId !== 0
            ? selectedIntegrationId
            : systemConfig.integrationScenarioID,
      };
    }

    setOpenBackDrop(true);
    postData(`execute`, propModel)
      .then((response) => {
        setOpenBackDrop(false);
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response.responseStatus === 'SUCCESS') {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        if (response.responseStatus === 'CONFLICT') {
          setAlert({
            showAlert: true,
            message: 'Process is already running, please try again later.',
            type: 'error',
          });
          return;
        }
        if (response.responseStatus === 'UNPROCESSABLE') {
          setAlert({
            showAlert: true,
            message: 'Request cannot be processed.',
            type: 'error',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setOpenBackDrop(false);
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
    setOpenConfirmation(false);
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      showAlert: false,
      message: '',
      type: 'success',
    });
  };

  const onOpenPropertySelection = (selectedValue) => {
    // if (includePropertyClassification) {
    //   setSelectedRowIds([]);
    // }
    if (selectedValue === 'selected') {
      setTempProperties(properties);
      setTempRowIds(selectedRowIds);
      setTempIncludePropertyClassification(includePropertyClassification);
      setOpenPropertySelection(true);
    } else if (selectedValue === 'addProps') {
      setOpenAddedProperties(true);
    }
  };

  const getDistinctSelectedProperties = () => {
    const selectedPropertiesFromRowIds = selectedRowIds.map(
      (i) => properties[i],
    );
    const distinctSelectedProperties = selectedPropertiesFromRowIds
      .map((item) => item.propertyId)
      .filter((value, index, self) => self.indexOf(value) === index);
    return distinctSelectedProperties;
  };

  const getSelectedProperties = () => {
    const selectedProps = [];
    if (selectPropertyValue === 'selected') {
      return getDistinctSelectedProperties();
    } else if (selectPropertyValue === 'addProps') {
      return addedProperties;
    }
    return selectedProps;
  };

  return (
    <Container maxWidth="xl">
      <>
        <ToastAlert
          alertValues={alert}
          handleAlertClose={handleAlertClose}
        ></ToastAlert>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) =>
              Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: ['center', 'left'],
          }}
        >
          <>
            <Typography
              variant="h5"
              style={{
                backgroundColor: 'cornflowerblue',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Argus - On Demand Scheduler
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: ['center', 'left'],
                border: 'solid',
                padding: 1.5,
                borderWidth: '1px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textDecorationLine: 'underline',
                      fontWeight: 'bold',
                    }}
                  >
                    Select Properties:
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="argus-properties-controlled-radio-buttons-group"
                      name="argus-properties-controlled-radio-buttons-group"
                      value={selectPropertyValue}
                      onChange={(event, value) => {
                        setSelectPropertyValue(value);
                        if (value && value !== 'all') {
                          onOpenPropertySelection(value);
                        } else {
                          setSelectedRowIds(
                            Array.from(
                              { length: properties.length },
                              (_, i) => i,
                            ),
                          );
                        }
                      }}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio size="small" />}
                        label="ALL"
                      />
                      <FormControlLabel
                        value="selected"
                        control={<Radio size="small" />}
                        label="Selected Properties"
                      />
                      {systemConfig?.enableBulkPropertySelection && (
                        <FormControlLabel
                          value="addProps"
                          control={<Radio size="small" />}
                          label="Add Properties"
                        />
                      )}
                    </RadioGroup>
                    {selectPropertyValue === 'addProps' && (
                      <AddProperties
                        onSelect={(props) => {
                          setAddedProperties(props);
                          setOpenAddedProperties(false);
                        }}
                        openAddedProperties={openAddedProperties}
                        defaultProperties={addedProperties}
                        onCancel={() => {
                          setOpenAddedProperties(false);
                        }}
                      ></AddProperties>
                    )}
                    <Typography
                      variant="h6"
                      textAlign={'center'}
                      style={{
                        backgroundColor: 'lightgrey',
                        fontSize: '15px',
                        marginBottom: '8px',
                      }}
                    >
                      {selectPropertyValue === 'all'
                        ? 'All'
                        : selectPropertyValue === 'selected'
                        ? selectedRowIds?.length
                        : addedProperties?.length}{' '}
                      properties are selected
                      {selectPropertyValue !== 'all' && (
                        <IconButton
                          color="success"
                          aria-label="edit properties"
                          onClick={() =>
                            onOpenPropertySelection(selectPropertyValue)
                          }
                          style={{ height: '10px' }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      textDecorationLine: 'underline',
                      fontWeight: 'bold',
                    }}
                  >
                    Use Template?
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="argus-usetemplate-controlled-radio-buttons-group"
                      name="argus-usetemplate-controlled-radio-buttons-group"
                      value={useTemplate ? 'yes' : 'no'}
                      onChange={(event, value) => {
                        if (value === 'yes') {
                          setOpenTemplateConfirmation(true);
                        } else {
                          setUseTemplate(false);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                    {templates.length > 0 && useTemplate && (
                      <>
                        <Autocomplete
                          value={selectedTemplatePortfolio}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event: any, newValue: any) => {
                            setSelectedTemplatePortfolio(newValue);
                            setFilteredTemplates(
                              templates.filter(
                                (x) => x.portfolioId === newValue.id,
                              ),
                            );
                          }}
                          id="template-portfolio-select"
                          getOptionLabel={(option) => option.name}
                          options={distinctTemplatePortfolios}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Portfolio"
                              variant="standard"
                            />
                          )}
                        />
                        <Autocomplete
                          value={selectedTemplate}
                          isOptionEqualToValue={(option, value) =>
                            option.templatePropertyId ===
                            value.templatePropertyId
                          }
                          onChange={(event: any, newValue: any) => {
                            setSelectedTemplate(newValue);
                          }}
                          id="template-select"
                          getOptionLabel={(option) =>
                            option.templatePropertyName
                          }
                          options={filteredTemplates}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Template"
                              variant="standard"
                            />
                          )}
                        />
                      </>
                    )}
                  </FormControl>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        letterSpacing: 'normal',
                      }}
                    >
                      Note: Template should only be used when importing to an
                      empty scenario in AE.
                    </Typography>
                  </div>
                  <ConfirmDialog
                    title="Confirmation?"
                    open={openTemplateConfirmation}
                    handleClose={() => setOpenTemplateConfirmation(false)}
                    onConfirm={() => setUseTemplate(true)}
                  >
                    Template should only be used when importing to an empty
                    scenario in AE. Are you sure you want to UseTemplate?
                  </ConfirmDialog>
                </Grid>
                <Grid item xs={4}>
                  <Scenario
                    onSelect={(id) => setSelectedIntegrationId(id)}
                    systemConfig={systemConfig}
                    state={state}
                  ></Scenario>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        fontWeight: 'bold',
                        letterSpacing: 'normal',
                      }}
                    >
                      Note: Please confirm your Scenario before updating.
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: ['center', 'left'],
                padding: 1.5,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <ModuleSelection
                    getSelectedModules={(modules) =>
                      setSelectedModulesModel(modules)
                    }
                    systemConfig={systemConfig}
                  ></ModuleSelection>
                </Grid>
                <Grid item xs={8}>
                  <UpdateManagementFee
                    state={state}
                    propModel={{
                      integrationScenarioId:
                        systemConfig?.integrationScenarioID,
                      scheduledUser: state.user.name,
                      includeAllProperties: selectPropertyValue === 'all',
                      selectedProperties: getSelectedProperties(),
                    }}
                    setAlert={setAlert}
                    setOpenBackDrop={setOpenBackDrop}
                  ></UpdateManagementFee>
                  <UnitCleanUp
                    state={state}
                    selectedIntegrationId={systemConfig?.integrationScenarioID}
                    setAlert={setAlert}
                    setOpenBackDrop={setOpenBackDrop}
                  ></UnitCleanUp>
                  <DeleteIncrementalHistory
                    state={state}
                    selectedProperties={getSelectedProperties()}
                    setAlert={setAlert}
                    setOpenBackDrop={setOpenBackDrop}
                  ></DeleteIncrementalHistory>
                  <InfoSection></InfoSection>
                </Grid>
              </Grid>
            </Box>

            {selectedModulesModel.includeGLData && (
              <GLAccounts
                getGLModel={(glModule) => setGlModuleModel(glModule)}
                systemConfig={systemConfig}
              ></GLAccounts>
            )}
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setManagementFeeAutoRun(
                    selectedModulesModel.includeContractualTenants ||
                      selectedModulesModel.includeGLData ||
                      selectedModulesModel.includeRecoveries,
                  );
                  setOpenConfirmation(true);
                }}
              >
                Execute
              </Button>

              <Button
                style={{
                  margin: '10px',
                }}
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  setOpenTerminateConfirmation(true);
                }}
              >
                Terminate Previous Run
              </Button>
            </div>
          </>
        </Box>
        {openPropertySelection && (
          <PropertySelection
            openPropertySelection={openPropertySelection}
            handleDone={(rs) => {
              setOpenPropertySelection(false);
              setTimeout(() => {
                setSelectedRowIds(Object.keys(rs).map(Number));
              }, 0);
            }}
            properties={properties}
            selectedRowIds={selectedRowIds}
            includePropertyClassification={includePropertyClassification}
            setincludePropertyClassification={setincludePropertyClassification}
            onCancel={() => {
              setProperties(tempProperties);
              setSelectedRowIds(tempRowIds);
              setincludePropertyClassification(
                tempIncludePropertyClassification,
              );
              setOpenPropertySelection(false);
            }}
          ></PropertySelection>
        )}
        <ArgusLogs></ArgusLogs>
        <ConfirmDialog
          title="Confirmation?"
          open={openConfirmation}
          handleClose={() => setOpenConfirmation(false)}
          onConfirm={() => onExecute(false)}
        >
          Are you sure you want to execute?
          <div>
            <Typography
              variant="caption"
              style={{
                letterSpacing: 'normal',
              }}
            >
              Note: Please confirm your Scenario.
            </Typography>
          </div>
          {(selectedModulesModel.includeContractualTenants ||
            selectedModulesModel.includeGLData ||
            selectedModulesModel.includeRecoveries) && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={managementFeeAutoRun}
                  onChange={(event) => {
                    setManagementFeeAutoRun(event.target.checked);
                  }}
                />
              }
              label="Run Management fees"
            />
          )}
        </ConfirmDialog>

        <ConfirmDialog
          title="Confirmation?"
          open={openTerminateConfirmation}
          handleClose={() => setOpenTerminateConfirmation(false)}
          onConfirm={() => onExecute(true)}
        >
          Are you sure you want to terminate previous run?
        </ConfirmDialog>
      </>
    </Container>
  );
};

export default ArgusJobDetails;
