import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';

import { MainTable } from '../../components/MainTable';
import { fetchData } from '../../lib/fetchData';

const BankTransactionLogs = ({}) => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const columns = [
    {
      field: 'importId',
      headerName: 'Import Id',
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          setOpenImportModal(true);
          setSelectedCell(params.row); // Assuming the 'details' field contains the cell data
        };
        return params.value === 0 ? (
          '-NA-'
        ) : (
          <a href="#" onClick={handleDetailsClick}>
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'lastRunTime',
      headerName: 'Lastrun Date Time',
      flex: 1,
      minWidth: 200,
    },
    { field: 'startCount', headerName: 'Start Count', flex: 1, minWidth: 100 },
    { field: 'endCount', headerName: 'End Count', flex: 1, minWidth: 100 },
    { field: 'totalCount', headerName: 'Total Count', flex: 1, minWidth: 100 },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
    { field: 'remarks', headerName: 'Remarks', flex: 1, minWidth: 100 },
    { field: 'requestedBy', headerName: 'RequestedBy', flex: 1, minWidth: 100 },
    { field: 'resourceId', headerName: 'ResourceId', flex: 1, minWidth: 100 },
  ];

  const closeModal = () => {
    setOpenImportModal(false);
  };
  const [tableData, setTableData] = useState<
    | [
        {
          id: number;
          importId: number;
          lastRunTime: Date;
          startCount: number;
          endcount: number;
          totalCount: number;
          status: string;
          remarks: string;
          requestedBy: string;
          resourceId: string;
        },
      ]
    | []
  >([]);

  useEffect(() => {
    fetchData(`banktransaction/logs`, (data) => {
      setTableData(data);
    });
  }, []);

  return (
    <Box
      sx={{
        height: 240,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
      }}
    >
      <Typography variant="h5">Bank Data Transaction Logs</Typography>
      <MainTable
        rows={tableData}
        columns={columns}
        hideFooter
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
      />
      {selectedCell !== null && (
        <Dialog open={openImportModal} fullWidth={true} maxWidth={'lg'}>
          <DialogTitle>Status: {selectedCell.status}</DialogTitle>
          <DialogContent>{selectedCell.remarks}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default BankTransactionLogs;
