import {
  AlertColor,
  Backdrop,
  Box,
  CircularProgress,
  Container,
} from '@mui/material';
import { useState } from 'react';

import BankTransaction from '../dataservice/blackline/bank-transaction';
import BankTransactionLogs from '../dataservice/blackline/bank-transaction-logs';
import ToastAlert from '../hooks/toast-alert';
import { useAuthContext } from '../lib/auth/AuthContext';

const BlackLineBankTransaction = ({}) => {
  const { state } = useAuthContext();
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    type: AlertColor;
    message: string;
  }>({
    showAlert: false,
    type: null,
    message: null,
  });
  const [openBackdrop, setOpenBackDrop] = useState(false);

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      showAlert: false,
      message: '',
      type: 'success',
    });
  };

  return (
    <Container maxWidth="xl">
      <ToastAlert
        alertValues={alert}
        handleAlertClose={handleAlertClose}
      ></ToastAlert>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 3,
          textAlign: ['center', 'left'],
        }}
      >
        <BankTransaction
          authState={state}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
        ></BankTransaction>
      </Box>
      <Box
        sx={{
          mt: 10,
        }}
      >
        <BankTransactionLogs />
      </Box>
    </Container>
  );
};

export default BlackLineBankTransaction;
