type RouteType = {
  name: string;
  path: string;
  submenu?: {
    [key: string]: {
      name: string;
      path: string;
    };
  };
};

// To Do -> Use constants in routeConfig too
export const ROUTES: { [key: string]: RouteType } = {
  blackline: {
    name: 'Black Line',
    path: 'blackline',
    submenu: {
      scheduler: {
        name: 'Scheduler',
        path: 'blackline-scheduler',
      },
      banktransaction: {
        name: 'Bank Transaction',
        path: 'blackline-banktransaction',
      },
    },
  },
  anaplan: {
    name: 'Ana Plan',
    path: 'anaplan',
    submenu: {
      scheduler: {
        name: 'Scheduler',
        path: 'anaplan-scheduler',
      },
    },
  },
  argus: {
    name: 'Argus',
    path: 'argus',
  },
};
