import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';

const AddProperties = ({
  onSelect,
  openAddedProperties,
  defaultProperties,
  onCancel,
}) => {
  const [addedProperties, setAddedProperties] = useState([]);

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'property',
          header: 'Property',
          size: 40,
          filterFn: 'contains',
        },
      ] as MRT_ColumnDef<any>[],
    [],
  );

  useEffect(() => {
    setAddedProperties(defaultProperties);
  }, [defaultProperties, openAddedProperties]);

  const setDistinctProperties = (strProperty) => {
    const lstProperties = strProperty
      .replace(/\n/g, '')
      .split('^')
      .filter(Boolean);

    const distinctSelectedProperties = lstProperties.filter(
      (value, index, self) => self.indexOf(value) === index,
    );
    setAddedProperties(distinctSelectedProperties);
  };

  return (
    <>
      <Dialog open={openAddedProperties} fullWidth={true} maxWidth={'lg'}>
        <DialogContent>
          <div
            style={{
              marginBottom: '5px',
            }}
          >
            <TextField
              id="outlined-multiline-flexible"
              label="Add properties here"
              multiline
              maxRows={2}
              fullWidth
              size="small"
              placeholder="Ex: br1234^br4567"
              defaultValue={defaultProperties.join('^')}
              onChange={(event) => {
                if (event.target.value === '') {
                  setAddedProperties([]);
                } else {
                  setDistinctProperties(event.target.value);
                }
              }}
            />
          </div>
          <Typography
            variant="body1"
            style={{
              maxWidth: '385px',
              fontSize: '12px',
              fontStyle: 'italic',
              marginBottom: '15px',
            }}
          >
            Note: Delimiter ^ must be used between properties. Ex: br1234^br4567
          </Typography>
          {addedProperties && openAddedProperties && (
            <div style={{ height: 350, width: '100%' }}>
              <MaterialReactTable
                columns={columns}
                data={addedProperties.map((item) => {
                  return { property: item };
                })}
                enableStickyHeader
                enableStickyFooter
                muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
                initialState={{ showColumnFilters: true, density: 'compact' }}
                enableTopToolbar={false}
                enablePagination={false}
              />
            </div>
          )}
          <Typography
            variant="h6"
            textAlign={'left'}
            style={{
              fontSize: '15px',
              marginTop: '3px',
              maxWidth: '250px',
            }}
          >
            {addedProperties?.length} properties are selected
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              onSelect(addedProperties);
            }}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProperties;
